export const site = process.env.NODE_ENV === 'development' ? 'http://localhost:9000' : '';

export type PackageVersion = {
  Name: string,
  Version: string,
}

export const getPackageNamesByModule = async (mod: string):  Promise<PackageVersion[]> => {
  const res = await fetch(`${site}/apiv1/m/${mod}`);
  const data = await res.json();
  if (!res.ok) throw new Error(data.error);
  return data.data;
}
