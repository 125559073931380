import { Flex } from "antd"
import { Logo } from "./Logo"
import { css } from "@emotion/css"

export const Header = () => {
  return (
    <Flex justify="space-between" align="center" className={css`
      height: 100px;
      padding: 60px;
    `}>
      <Logo />
    </Flex>
  )
}
