import { css } from '@emotion/css'
import { Flex } from 'antd'
import { Link } from "react-router-dom";

export const Logo = () => {
  return (
    <Link to="/">
      <Flex className={css`
        font-size: 1.3em;
        font-weight: 500;
        text-decoration: none;
        user-select: none;
      `}>
        <span className={css`
          color: gray;
        `}>NoModule</span>
        <span className={css`
          color: orange;
        `}>Named</span>
      </Flex>
    </Link>
  )
}
