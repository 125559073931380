import { css } from "@emotion/css";
import { ReactNode } from "react";

type ContainerProps = {
  children: ReactNode,
  className?: string
}

export const Container = (props: ContainerProps) => {
  return (
    <div className={css`
      padding: 0 60px;
    `}>
      <div className={props.className}>
        {props.children}
      </div>
    </div>
  )
}
