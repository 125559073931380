import { Typography } from "antd"
import { Container } from "../components/Container"
import { Header } from "../components/Header"

// random pypi package
export const IndexPage = () => {
  return (
    <div>
      <Header />
      <Container>
        <Typography.Title>Welcome to nomodulenamed.com!</Typography.Title>
      </Container>
    </div>
  )
}
